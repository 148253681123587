import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import LecturerService from "../services/LecturerService.js";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import AuthService from "../services/AuthService.js";
import { useCookies } from "react-cookie";
import BorrowingService from "../services/BorrowingService.js";
import Loading from "./Loading.js";

const NewForm = ({ setOpenForm, setSuccessAlert }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [loading, setLoading] = useState(false);
  const [firstLecturer, setFirstLecturer] = useState("");
  const [secondLecturer, setSecondLecturer] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [courseName, setCourseName] = useState("");
  const [meetingNumber, setMeetingNumber] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [reason, setReason] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [lecturers, setLecturers] = useState([]);
  const [open, setOpen] = useState(false);
  const [calendar, setCalendar] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    LecturerService.getAllLecturers()
      .then((result) => {
        setLecturers(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (item) => {
    setCalendar([item.selection]);
    console.log(format(item.selection.startDate, "MM/dd/yyyy"));
    console.log(format(item.selection.endDate, "MM/dd/yyyy"));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleValidation = () => {
    if (firstLecturer == "") {
      setErrorMsg("Must choose a valid First Lecturer");
      return false;
    }
    if (secondLecturer == firstLecturer) {
      setErrorMsg("First Lecturer cannot be the same as Second Lecturer");
      return false;
    }
    if (courseCode == "") {
      setErrorMsg("Course Code is required");
      return false;
    }
    if (courseName == "") {
      setErrorMsg("Course Name is required");
      return false;
    }
    if (meetingNumber == "") {
      setErrorMsg("Meeting must greater than 0");
      return false;
    }
    if (reason == "") {
      setErrorMsg("Reason must be filled");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const valid = handleValidation();

    console.log(errorMsg);
    if (valid == true) {
      const userData = await AuthService.parseToken(cookies.token);
      // const data = {
      //   lecturer_email: firstLecturer,
      //   second_lecturer_email: secondLecturer,
      //   student_email: userData.user.User.UserName,
      //   student_name: userData.user.User.Name,
      //   course_code: courseCode,
      //   course_name: courseName,
      //   meeting_number: meetingNumber,
      //   check_out_date: format(calendar[0].startDate, "yyyy-MM-dd"),
      //   check_in_date: format(calendar[0].endDate, "yyyy-MM-dd"),
      //   comments: reason,
      //   document: selectedFiles
      // };

      const data =  new FormData;
      data.append("lecturer_email", firstLecturer);
      data.append("second_lecturer_email", secondLecturer);
      data.append("student_email", userData.User.email);
      data.append("student_name", userData.User.name);
      data.append("course_code", courseCode);
      data.append("course_name", courseName);
      data.append("meeting_number", meetingNumber);
      data.append("check_out_date", format(calendar[0].startDate, "yyyy-MM-dd"));
      data.append("check_in_date", format(calendar[0].endDate, "yyyy-MM-dd"));
      data.append("comments", reason);
      if (selectedFiles) {
        selectedFiles.forEach((file) => {
          data.append(`document[]`, file);
        });
      }

      console.log(...data);
      const result = await BorrowingService.newBorrowing(data, cookies.token);
      console.log(result);
      if (result == "Success") {
        setOpenForm(false);
        setSuccessAlert(true);
      }
    }
    setLoading(false);
  };

  const formattedStartDate = calendar[0]
    ? format(calendar[0].startDate, "MM/dd/yyyy")
    : "";
  const formattedEndDate = calendar[0]
    ? format(calendar[0].endDate, "MM/dd/yyyy")
    : "";

  return (
    <div className="bg-white p-10 rounded-xl">
      <form onSubmit={handleSubmit}>
        <div className="relative flex flex-wrap justify-center gap-7 max-w-screen max-h-screen overflow-y-auto">
          <div className="lg:w-[40vw] md:w-[50vw] sm:w-[60vw] max-h-[80vh] mx-auto flex flex-col">
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="first_lecturer"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select First Lecturer
              </label>
              <select
                id="first_lecturer"
                defaultValue=""
                onChange={(e) => setFirstLecturer(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" disabled>
                  Choose a Lecturer
                </option>
                {lecturers.map((lecturer) => {
                  return (
                    <option
                      key={lecturer.id}
                      value={lecturer.email}
                      className="w-fit"
                    >
                      {lecturer.email}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="second_lecturer"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select Second Lecturer
              </label>
              <select
                id="second_lecturer"
                defaultValue=""
                onChange={(e) => setSecondLecturer(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" disabled>
                  Choose a Lecturer
                </option>
                {lecturers.map((lecturer) => {
                  return (
                    <option
                      key={lecturer.id}
                      value={lecturer.email}
                      className="w-fit"
                    >
                      {lecturer.email}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="course_code"
                id="course_code"
                onChange={(e) => setCourseCode(e.target.value)}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="course_code"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Course Code
              </label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="course_name"
                id="course_name"
                onChange={(e) => setCourseName(e.target.value)}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="course_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Course Name
              </label>
            </div>
            <div className="grid md:grid-cols-3 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  min={1}
                  name="meeting_number"
                  onChange={(e) => setMeetingNumber(e.target.value)}
                  id="meeting_number"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="meeting_number"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Meeting Number
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  value={formattedStartDate}
                  readOnly
                  onClick={() => setOpen((open) => !open)}
                  type="text"
                  name="check_out"
                  id="check_out"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="check_out"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Check Out
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  value={formattedEndDate}
                  readOnly
                  onClick={() => setOpen((open) => !open)}
                  type="text"
                  name="check_in"
                  id="check_in"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="check_in"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Check In
                </label>
              </div>
            </div>
            <div className="flex justify-center items-center gap-3 mb-5">
              <input
                type="file"
                className="hidden p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Write your thoughts here..."
                multiple
                onChange={handleFileChange}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Reason
              </label>
              <textarea
                id="message"
                rows="2"
                onChange={(e) => setReason(e.target.value)}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Write your thoughts here..."
              ></textarea>
            </div>
          </div>
          {open && (
            <div className="">
              <DateRange
                editableDateInputs={true}
                onChange={handleChange}
                moveRangeOnFirstSelection={false}
                ranges={calendar}
                minDate={new Date()}
              />
            </div>
          )}
        </div>
        <div className="flex items-center">
          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-2">
            Submit
          </button>
          <label
            htmlFor="message"
            className="block mb-2 text-large font-bold text-red-600 m-2"
          >
            {errorMsg}
          </label>
        </div>
      </form>
      {loading && (
        <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center transition-opacity duration-500">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default NewForm;
